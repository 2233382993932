<template>
  <div>
    <div class="d-flex justify-content-end align-items-center">
      <b-button-group size="sm" class="my-1 mb-3">
        <a title="25 Miles Around" class="btn border-left-pill btn-secondary" target="_blank" rel="noreferrer noopener" :href="getCDLink(25)">
          <i class="fas fa-angle-right"></i>
        </a>
        <a title="50 Miles Around" class="btn btn-secondary" target="_blank" rel="noreferrer noopener" :href="getCDLink(50)">
          <i class="fas fa-angle-double-right"></i>
        </a>
        <a title="100 Miles Around" class="btn border-left-pill btn-secondary" target="_blank" rel="noreferrer noopener" :href="getCDLink(100)">
          <i class="fas fa-angle-right"></i>
          <i style="margin-left: -0.28rem" class="fas fa-angle-double-right"></i>
        </a>
      </b-button-group>
    </div>

    <div v-if="order.carrierId" class="callout callout-danger mb-3">Order has already been dispatched!</div>

    <div v-if="order.leadTypeId==3 && !order.carrierPay" class="callout callout-danger mb-3">Carrier pay is not set!</div>

    <div class="card mb-4">
      <div class="card-body">
        <b-form inline @submit.prevent="submit">
          <b-form-group label="Loadboard" class="mr-3 mb-2"><b-select class="custom-select" v-model="form.loadboardId" :options="loadboardOptions" :disabled="!!form.id" /></b-form-group>
          <b-form-group label="Type" class="mr-3 mb-2"><b-select class="custom-select" v-model="form.type" :options="typeOptions" :disabled="!!form.id" /></b-form-group>
          <b-form-group label="Pickup Zip" class="mr-3 mb-2"><b-input v-model="form.pickupZip" type="text" class="zip" /></b-form-group>
          <b-form-group label="Delivery Zip" class="mr-3 mb-2"><b-input v-model="form.deliveryZip" type="text" class="zip" /></b-form-group>
          <b-form-group label="Transport type" class="mr-3 mb-2"><b-select class="custom-select" v-model="form.transportTypeId" :options="transportTypeOptions" /></b-form-group>
          <b-form-group label="Carrier Pay" class="mr-3 mb-2"><b-input v-model="form.carrierPay" type="text" class="cpay" /></b-form-group>
          <b-form-group label="Unpost at" class="mr-3 mb-2"><flat-pickr class="form-control" v-model="form.unpostAt" placeholder="Select time" :config="fltConfig" value=""/></b-form-group>
          <b-form-group label="Note" class="mr-3 mb-2"><b-input v-model="form.note" type="text" /></b-form-group>
          <template v-if="form.loadboardId == 1">
            <b-form-group label="Marketplace" class="mr-3 mb-2"><b-select class="custom-select" v-model="form.marketplace" :options="marketplaceOptions" /></b-form-group>
            <b-form-group label="Allow Offers" class="mr-3 mb-2"><b-select class="custom-select" v-model="form.params.offers" :options="booleanOptions" /></b-form-group>
            <b-form-group v-if="false" label="Auto Dispatch" class="mr-3 mb-2"><b-select class="custom-select" v-model="form.params.offersAutoAccept" :options="booleanOptions" /></b-form-group>
          </template>
          <LoadingIndicator v-if="saving" />
          <button class="btn btn-primary mt-3" v-else>{{ !form.id ? 'Post' : 'Repost' }}</button>
          <button class="btn btn-secondary mt-3 ml-3" v-if="form.id" @click.prevent="reset">Reset</button>
        </b-form>
      </div>
    </div>

    <template v-for="loadboard in loadboards">
      <div class="card mb-4" v-if="posts(loadboard.id).length" :key="loadboard.id">
        <div class="card-body">
          <h3>{{ loadboard.name }}</h3>
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Agent</th>
                <th>Type</th>
                <th>Pickup</th>
                <th>Delivery</th>
                <th>Transport type</th>
                <th>Carrier pay</th>
                <th>Note</th>
                <th>Unpost at</th>
                <th>Posted at</th>
                <th>Removed at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in posts(loadboard.id)" :key="item.id" :class="rowClass(item)">
                <td>{{ item.leadId }}-{{ item.id }}</td>
                <td>{{ item.user && item.user.name }}</td>
                <td>{{ typeName(item.type) }}</td>
                <td>{{ item.pickupZip }}</td>
                <td>{{ item.deliveryZip }}</td>
                <td>{{ transportTypeName(item.transportTypeId) }}</td>
                <td>{{ item.carrierPay }}</td>
                <td>{{ item.note }}</td>
                <td>{{ item.unpostAt | formatDateTime }}</td>
                <td>{{ item.createdAt | formatDateTime }}</td>
                <td>{{ item.deletedAt | formatDateTime }}</td>
                <td>
                  <b-btn size="xs" variant="primary" @click.prevent="edit(item)" class="mr-3" v-if="!item.deletedAt">Edit</b-btn>
                  <b-btn size="xs" variant="danger" @click.prevent="unpost(item.id)" v-if="!item.deletedAt">Remove</b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>

    <div class="card mb-4">
      <div class="card-body">
        <h3>Automated actions</h3>
        <b-form-group label="Automatic posting to loadboards"><b-radio-group v-model="autopostDisabled" :options="autopostStatus" @input="setAutopostStatus" /></b-form-group>
        <b-form-group label="Automatic auto raise"><b-radio-group v-model="autoraiseDisabled" :options="autoraiseStatus" @input="setAutoraiseStatus" /></b-form-group>

        <b-form inline @submit.prevent="submitAutoraise" class="mb-3" v-if="autoraiseDisabled">
          <b-form-group label="Raise at" class="mr-3"><flat-pickr class="form-control" v-model="autoraiseForm.raiseAt" placeholder="Select time" :config="fltConfig" value="" /></b-form-group>
          <b-form-group label="Amount" class="mr-3"><b-input v-model="autoraiseForm.amount" type="text"/></b-form-group>
          <b-form-group label="Repetition interval (minutes)" class="mr-3"><b-input v-model="autoraiseForm.repetitionInterval" type="text"/></b-form-group>
          <b-form-group label="Stop at" class="mr-3"><flat-pickr class="form-control" v-model="autoraiseForm.stopAt" placeholder="Select time" :config="fltConfig" value="" /></b-form-group>

          <LoadingIndicator v-if="savingAutoraise" />
          <button class="btn btn-primary mt-4" v-else>{{ 'Create' }}</button>
        </b-form>

        <table class="table" v-if="autoraises.length && autoraiseDisabled">
          <thead>
            <tr>
              <th>Agent</th>
              <th>Raise at</th>
              <th>Amount</th>
              <th>Repetition interval</th>
              <th>Stop at</th>
              <th>Created at</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in autoraises" :key="item.id" :class="rowClass(item)">
              <td>{{ item.user && item.user.name }}</td>
              <td>{{ item.raiseAt | formatDateTime }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.repetitionInterval }}</td>
              <td>{{ item.stopAt | formatDateTime }}</td>
              <td>{{ item.createdAt | formatDateTime }}</td>
              <td>
                <b-btn size="xs" variant="danger" @click.prevent="removeAutoraise(item.id)" v-if="!item.deletedAt">Remove</b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import moment from 'moment';

let loadboardOptions = [{ value: 1, text: 'Central Dispatch' }];
if(process.env.VUE_APP_ENV != 'prod') loadboardOptions.push({ value: 2, text: 'Hotcarloads' });
loadboardOptions.push({ value: 3, text: 'Super Dispatch' });

export default {
  name: 'OrderLoadboards',
  components: {},
  data() {
    return {
      fltConfig: {
        inline: false,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        altInput: true,
        altFormat: 'm/d/Y H:i',
        allowInput: false,
      },
      autoraiseStatus : [
        { text: 'Enabled', value: 0 },
        { text: 'Disabled', value: 1 },
      ],
      autopostStatus : [
        { text: 'Enabled', value: 0 },
        { text: 'Disabled', value: 1 },
      ],
      autoraiseDisabled: this.order.isAutoraiseDisabled,
      autopostDisabled: this.order.isAutopostDisabled,
      autoraises: [],
      loadboardPosts: [],
      loadboards: [
        { id: 1, name: 'Central Dispatch' },
        { id: 2, name: 'Hotcarloads' },
        { id: 3, name: 'Super Dispatch' },
      ],
      savingAutoraise: false,
      autoraiseForm: {
        amount: null,
        repetitionInterval: null,
        raiseAt: null,
        stopAt: null,
      },
      form: {
        id: null,
        loadboardId: 1,
        leadId: this.order.id,
        type: 1,
        transportTypeId: this.order.transportTypeId,
        pickupZip: this.order.pickupLocation.zip,
        deliveryZip: this.order.deliveryLocation.zip,
        note: '',
        carrierPay: this.order.carrierPay,
        unpostAt: null,
        marketplace: 1,
        params: {
          offers: 1,
          offersAutoAccept: 0,
        },
      },
      typeOptions: [
        { text: 'Regular', value: 1 },
        { text: 'Visibility', value: 2 },
        { text: 'Backup', value: 3 },
      ],
      transportTypeOptions: [
        { value: null, text: '-' },
        { value: 1, text: 'Open' },
        { value: 2, text: 'Enclosed' },
      ],
      marketplaceOptions: [
        { text: 'Everywhere', value: 1 },
        { text: 'Private Only', value: 2 },
        { text: 'Public Only', value: 3 },
      ],
      booleanOptions : [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 0 },
      ],
      loadboardOptions: loadboardOptions,
      saving: false,
    };
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    posts(id) {
      return this.loadboardPosts.filter(x => x.loadboardId == id);
    },
    typeName(id) {
      if (id == 1) return 'Regular';
      if (id == 2) return 'Visibility';
      if (id == 3) return 'Backup';
      return '-';
    },
    transportTypeName(id) {
      if (id == 1) return 'Open';
      if (id == 2) return 'Enclosed';
      return '-';
    },
    getCDLink(distance) {
      let url = 'https://app.centraldispatch.com/search?search='+
        '~(vC~(mn~1)~tLPW~2~opt~%27all~ofst~0~lmt~50~sF~(~(n~%27pup~d~%27ASC)~(n~%27dMA~d~%27ASC))~dBS~false~sPSO~false~sTOT~false~rT~%27Open~l~(~(c~%27'+
        this.order.pickupLocation.city.replace(' ','*20')+
        '~st~%27'+
        this.order.pickupLocation.state +
        //'~zC~%27'+
        //this.order.pickupLocation.zip +
        '~r~' +
        distance +
        '~s~%27Pickup)~(c~%27'+
        this.order.deliveryLocation.city.replace(' ','*20')+
        '~st~%27'+
        this.order.deliveryLocation.state +
        //'~zC~%27' +
        //this.order.deliveryLocation.zip +
        '~r~' +
        distance +
        '~s~%27Dropoff)))';
      return url;
    },

    async fetch() {
      const response = await ApiClient.loadboardPosts.get({ params: { leadId: this.order.id } });
      this.loadboardPosts = response.data.data;

      const response2 = await ApiClient.leads.autoraises.get({ leadId: this.order.id });
      this.autoraises = response2.data.data;
    },
    async submit() {
      let form = JSON.parse(JSON.stringify(this.form));

      form.params.marketplacePublic = (form.marketplace != 2 ? 1 : 0);
      form.params.marketplacePrivate = (form.marketplace != 3 ? 1 : 0);
      form.unpostAt = form.unpostAt ? moment(form.unpostAt).toISOString() : null;

      if(form.type == 2){ // Visibility orders
        form.params.marketplacePublic = 1;
        form.params.marketplacePrivate = 0;
        form.params.offers = 0;
        form.params.offersAutoAccept = 0;
      }

      let response;
      if (form.id) {
        response = await ApiClient.loadboardPosts.repost({ id: form.id, body: form });
      } else {
        response = await ApiClient.loadboardPosts.post({ body: form });
      }
      if (response.status === 200) {
        this.reset();
        this.fetch();
      }
    },
    async unpost(id) {
      await ApiClient.loadboardPosts.unpost({ id });
      this.fetch();
    },
    edit(item) {
      if(!item.params){
        item.params = {
          offers: 1,
          offersAutoAccept: 0,
        };
      }
      if(!item.marketplace){
        if(item.params.marketplacePublic && item.params.marketplacePrivate ) item.marketplace = 1;
        else if(item.params.marketplacePublic) item.marketplace = 3;
        else if(item.params.marketplacePrivate) item.marketplace = 2;
        else item.marketplace = 1;
      }
      this.form = Object.assign(this.form, item);
    },
    reset() {
      this.form = Object.assign(this.form, {
        id: null,
        loadboardId: 1,
        leadId: this.order.id,
        type: 1,
        transportTypeId: this.order.transportTypeId,
        pickupZip: this.order.pickupLocation.zip,
        deliveryZip: this.order.deliveryLocation.zip,
        note: '',
        carrierPay: this.order.carrierPay,
        unpostAt: null,
        marketplace: 1,
        params: {
          offers: 1,
          offersAutoAccept: 0,
        },
      });
    },
    rowClass(item) {
      if (item.deletedAt) return 'text-secondary';
      return '';
    },
    async submitAutoraise() {
      let form = Object.assign({},this.autoraiseForm);
      form.stopAt = form.stopAt ? moment(form.stopAt).toISOString() : null;
      form.raiseAt = form.raiseAt ? moment(form.raiseAt).toISOString() : null;
      if(!form.amount) form.amount = null;
      if(!form.repetitionInterval) form.repetitionInterval = null;

      let response = await ApiClient.leads.autoraises.post({ leadId: this.order.id, body: form });
      if (response.status === 200) {
        Object.assign(this.autoraiseForm, {
          amount: null,
          repetitionInterval: null,
          raiseAt: null,
          stopAt: null,
        });

        this.fetch();
      }
    },
    async removeAutoraise(id) {
      await ApiClient.leads.autoraises.delete({ leadId: this.order.id, id: id });
      this.fetch();
    },
    async setAutoraiseStatus() {
      console.log(this.order);
      await ApiClient.leads.autoraiseStatus({ id: this.order.id, body: { isAutoraiseDisabled: this.autoraiseDisabled } });
    },
    async setAutopostStatus() {
      console.log(this.order);
      await ApiClient.leads.autopostStatus({ id: this.order.id, body: { isAutopostDisabled: this.autopostDisabled } });
    }
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.zip {
  width: 70px;
}
.cpay {
  width: 90px;
}
</style>

