import { apiRequest } from '@/api/apiRequest';

/**
 * This is a 1-to-1 mapping between REST API back-end and our apiRequest helper.
 * Final methods *always* map to HTTP method.
 *
 * Pass data to request with:
 * params - these are URL query params (usually for GET requests)
 * body - this is JSON body of the content (usually for POST and PUT)
 * Example: ApiClient.carriers.post({body: {name: 'Super Cars'}})
 *
 * Id naming convention:
 * - If not nested, there is just one identifier named `id`
 * Example: ApiClient.cities.get({id: 12})
 *
 * - If nested, child object identifier is named `id` and parent identifier includes full name
 * Example: ApiClient.leads.offers.get({id: 7, leadId: 34})
 */
export const ApiClient = {
  carriers: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/carriers/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/carriers', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/carriers/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/carriers/${id}`, method: 'delete' }),
    history: ({ id, ...params }) => apiRequest({ ...params, path: `/carriers/${id}/history`, method: 'get' }),
    orders: ({ id, ...params }) => apiRequest({ ...params, path: `/carriers/${id}/orders`, method: 'get' }),
    logs: ({ id, ...params }) => apiRequest({ ...params, path: `/carriers/${id}/logs`, method: 'get' }),
    attachments: {
      get: ({ carrierId, id = '', ...params }) => apiRequest({ ...params, path: `/carriers/${carrierId}/attachments/${id}`, method: 'get' }),
      post: ({ carrierId, ...params }) => apiRequest({ ...params, path: `/carriers/${carrierId}/attachments`, method: 'post' }),
      put: ({ carrierId, id, ...params }) => apiRequest({ ...params, path: `/carriers/${carrierId}/attachments/${id}`, method: 'put' }),
      delete: ({ carrierId, id, ...params }) => apiRequest({ ...params, path: `/carriers/${carrierId}/attachments/${id}`, method: 'delete' }),
    },
    cdInvite: ({ carrierId, ...params }) => apiRequest({ ...params, path: `/carriers/${carrierId}/cd-invite`, method: 'post' }),
    cdResendInvite: ({ carrierId}) => apiRequest({ path: `/carriers/${carrierId}/cd-resend-invite`, method: 'post' }),
    cdRevokeInvite: ({ carrierId}) => apiRequest({ path: `/carriers/${carrierId}/cd-revoke-invite`, method: 'post' }),
    cdActivate: ({ carrierId}) => apiRequest({ path: `/carriers/${carrierId}/cd-activate`, method: 'post' }),
    cdDeactivate: ({ carrierId}) => apiRequest({ path: `/carriers/${carrierId}/cd-deactivate`, method: 'post' }),
  },
  cities: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/cities/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/cities', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/cities/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/cities/${id}`, method: 'delete' }),
  },
  contacts: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/contacts/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/contacts', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/contacts/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/contacts/${id}`, method: 'delete' }),
  },
  counties: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/counties/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/counties', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/counties/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/counties/${id}`, method: 'delete' }),
  },
  customers: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/customers/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/customers', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/customers/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/customers/${id}`, method: 'delete' }),
    history: ({ id, ...params }) => apiRequest({ ...params, path: `/customers/${id}/history`, method: 'get' }),
    orders: ({ id, ...params }) => apiRequest({ ...params, path: `/customers/${id}/orders`, method: 'get' }),
  },
  customerSearch: {
    get: ({ ...params }) => apiRequest({ ...params, path: `/customers`, method: 'get' }),
  },
  bulkActions: {
    post: params => apiRequest({ ...params, path: '/bulk-actions', method: 'post' }),
    assign: params => apiRequest({ ...params, path: '/bulk-actions/assign', method: 'post' }),
    raise: params => apiRequest({ ...params, path: '/bulk-actions/raise', method: 'post' }),
  },
  emails: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/emails/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/emails', method: 'post' }),
  },
  emailTemplates: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/email-templates/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/email-templates', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/email-templates/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/email-templates/${id}`, method: 'delete' }),
    preview: {
      get: ({ id, leadId = '', ...params }) => apiRequest({ ...params, path: `/email-templates/${id}/preview/${leadId}` }),
    },
  },
  sms: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/sms/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/sms', method: 'post' }),
  },
  smsTemplates: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/sms-templates/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/sms-templates', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/sms-templates/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/sms-templates/${id}`, method: 'delete' }),
    preview: {
      get: ({ id, leadId = '', ...params }) => apiRequest({ ...params, path: `/sms-templates/${id}/preview/${leadId}` }),
    },
  },
  dashboards: {
    topSalesAgents: () => apiRequest({ path: `/dashboards/top-sales-agents`, method: 'get' }),
    topMonthlySalesAgents: params => apiRequest({ ...params, path: '/dashboards/top-monthly-sales-agents', method: 'get' }),
    topDispatchAgents: () => apiRequest({ path: `/dashboards/top-dispatch-agents`, method: 'get' }),
    agentProductivity: () => apiRequest({ path: `/dashboards/agent-productivity`, method: 'get' }),
    hallOfFame: () => apiRequest({ path: `/dashboards/hall-of-fame`, method: 'get' }),
  },
  leadReclaims: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/lead-reclaims/${id}`, method: 'get' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/lead-reclaims/${id}`, method: 'put' }),
  },
  leadNotes: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/lead_notes/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/lead_notes', method: 'post' }),
  },
  leadStats: {
    get: ({ ...params }) => apiRequest({ ...params, path: `/lead-stats`, method: 'get' }),
  },
  liveLeads: ({ ...params }) => apiRequest({ ...params, path: `/live-leads`, method: 'get' }),
  leads: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/leads/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/leads', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}`, method: 'delete' }),
    cancel: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/cancel`, method: 'post' }),
    restore: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/restore`, method: 'post' }),
    onHold: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/on-hold`, method: 'post' }),
    removeHold: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/remove-hold`, method: 'post' }),
    pickedUp: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/picked-up`, method: 'post' }),
    delivered: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/delivered`, method: 'post' }),
    completed: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/completed`, method: 'post' }),
    notDelivered: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/not-delivered`, method: 'post' }),
    notPickedUp: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/not-picked-up`, method: 'post' }),
    issues: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/issues`, method: 'post' }),
    archive: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/archive`, method: 'post' }),
    promote: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/promote`, method: 'post' }),
    reflow: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/reflow`, method: 'post' }),
    claim: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/claim`, method: 'post' }),
    reclaim: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/reclaim`, method: 'post' }),
    dispatch: {
      post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/dispatch`, method: 'post' }),
      delete: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/dispatch/`, method: 'delete' }),
      resend: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/send-dispatch-sheet`, method: 'post' }),
    },
    followup: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/followup`, method: 'post' }),
    removeFollowup: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/followup`, method: 'delete' }),
    call: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/call`, method: 'post' }),
    history: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/history`, method: 'get' }),
    loadboards: {
      post: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/loadboards`, method: 'post' }),
    },
    autopostStatus: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/autopost-status`, method: 'post' }),
    autoraiseStatus: ({ id, ...params }) => apiRequest({ ...params, path: `/leads/${id}/autoraise-status`, method: 'post' }),
    autoraises: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/autoraises/${id}`, method: 'get' }),
      post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/autoraises`, method: 'post' }),
      delete: ({ leadId, id, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/autoraises/${id}`, method: 'delete' }),
    },
    notes: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/notes/${id}`, method: 'get' }),
      post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/notes`, method: 'post' }),
      put: ({ leadId, id, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/notes/${id}`, method: 'put' }),
      delete: ({ leadId, id, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/notes/${id}`, method: 'delete' }),
    },
    tags: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/tags/${id}`, method: 'get' }),
      post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/tags`, method: 'post' }),
      delete: ({ leadId, id, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/tags/${id}`, method: 'delete' }),
    },
    attachments: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/attachments/${id}`, method: 'get' }),
      post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/attachments`, method: 'post' }),
      put: ({ leadId, id, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/attachments/${id}`, method: 'put' }),
      delete: ({ leadId, id, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/attachments/${id}`, method: 'delete' }),
    },
    rivals: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/rivals/${id}`, method: 'get' }),
    },
    views: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/views/${id}`, method: 'get' }),
    },
    tasks: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/tasks/${id}`, method: 'get' }),
      post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/tasks`, method: 'post' }),
    },
    offers: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/offers/${id}`, method: 'get' }),
      post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/offers`, method: 'post' }),
      delete: ({ leadId, id, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/offers/${id}`, method: 'delete' }),
    },
    payments: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/payments/${id}`, method: 'get' }),
      post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/payments`, method: 'post' }),
      customers: {
        get: ({ leadId, ...params }) => apiRequest({ ...params, path: `/customers/${leadId}/customer-profile`, method: 'get' }),
        post: ({ customerId, ...params }) => apiRequest({ ...params, path: `/customers/${customerId}/customer-profile`, method: 'post' }),
        delete: ({ customerId, ...params }) => apiRequest({ ...params, path: `/customers/${customerId}/customer-profile`, method: 'delete' }),
        permission: {
          get: ({ leadId, ...params }) => apiRequest({ ...params, path: `/customers/${leadId}/grant-permission`, method: 'get' }),
        },
        single: {
          delete: ({ customerId, cardId, ...params }) => apiRequest({ ...params, path: `/customers/${customerId}/customer-profile/${cardId}`, method: 'delete' }),
        },
      },
      cards: {
        get: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/customer-profile`, method: 'get' }),
        post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/customer-profile`, method: 'post' }),
        delete: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/customer-profile`, method: 'delete' }),
      },
      profilepayment: {
        post: ({ ...params }) => apiRequest({ ...params, path: `/payment/customer-profile/`, method: 'post' }),
      },
      cc: {
        post: ({ ...params }) => apiRequest({ ...params, path: `/payment/cc/`, method: 'post' }),
      },
      shipperInvoice: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/send-shipper-invoice`, method: 'post' }),
    },
    vehicles: {
      get: ({ leadId, id = '', ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/vehicles/${id}`, method: 'get' }),
      post: ({ leadId, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/vehicles`, method: 'post' }),
      put: ({ leadId, id, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/vehicles/${id}`, method: 'put' }),
      delete: ({ leadId, id, ...params }) => apiRequest({ ...params, path: `/leads/${leadId}/vehicles/${id}`, method: 'delete' }),
    },
  },
  marketRates: {
    get: ({ ...params }) => apiRequest({ ...params, path: `/market-rates`, method: 'get' }),
    medianPrices: ({ ...params }) => apiRequest({ ...params, path: `/market-rates/median-prices`, method: 'get' }),
    heatMap: ({ ...params }) => apiRequest({ ...params, path: `/market-rates/heat-map`, method: 'get' }),
    activeRoutes: ({ ...params }) => apiRequest({ ...params, path: `/market-rates/active-routes`, method: 'get' }),
  },
  calculations: {
    get: ({ ...params }) => apiRequest({ ...params, path: `/calculations`, method: 'get' }),
    v2: ({ ...params }) => apiRequest({ ...params, path: `/test-calc`, method: 'get' }),
  },  
  loadboardPosts: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/loadboard-posts/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/loadboard-posts', method: 'post' }),
    repost: ({ id, ...params }) => apiRequest({ ...params, path: `/loadboard-posts/${id}`, method: 'put' }),
    unpost: ({ id, ...params }) => apiRequest({ ...params, path: `/loadboard-posts/${id}`, method: 'delete' }),
  },
  locations: {
    get: params => apiRequest({ ...params, path: '/locations', method: 'get' }),
  },
  notifications: {
    get: params => apiRequest({ ...params, path: '/notifications', method: 'get' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/notifications/${id}`, method: 'put' }),
    markRead: ({ ...params }) => apiRequest({ ...params, path: `/notifications/mark-read`, method: 'post' }),
    custom: {
      post: params => apiRequest({ ...params, path: '/notifications/custom', method: 'post' }),
    },
  },
  payments: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/payments/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/payments', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/payments/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/payments/${id}`, method: 'delete' }),
    sendReceipt: ({ id, ...params }) => apiRequest({ ...params, path: `/payments/${id}/send-receipt`, method: 'post' }),
  },
  reports: {
    payments: params => apiRequest({ ...params, path: '/reports/payments', method: 'get' }),
    orders: params => apiRequest({ ...params, path: '/reports/orders', method: 'get' }),
    sales: params => apiRequest({ ...params, path: '/reports/sales', method: 'get' }),
    salesDetails: params => apiRequest({ ...params, path: '/reports/sales-details', method: 'get' }),
    salesSourceDetails: params => apiRequest({ ...params, path: '/reports/sales-source-details', method: 'get' }),
    dispatchSales: params => apiRequest({ ...params, path: '/reports/dispatch-sales', method: 'get' }),
    dispatchSalesDetails: params => apiRequest({ ...params, path: '/reports/dispatch-sales-details', method: 'get' }),
    agentSourceSales: params => apiRequest({ ...params, path: '/reports/agent-source-sales', method: 'get' }),
    sourceSales: params => apiRequest({ ...params, path: '/reports/source-sales', method: 'get' }),
    sourceSalesDetails: params => apiRequest({ ...params, path: '/reports/source-sales-details', method: 'get' }),
    dealerSales: params => apiRequest({ ...params, path: '/reports/dealer-sales', method: 'get' }),
    dealerSalesDetails: params => apiRequest({ ...params, path: '/reports/dealer-sales-details', method: 'get' }),
    competition: params => apiRequest({ ...params, path: '/reports/competition', method: 'get' }),
    competitionReport: params => apiRequest({ ...params, path: '/reports/competition-report', method: 'get' }),
    competitionReportDetails: params => apiRequest({ ...params, path: '/reports/competition-report-details', method: 'get' }),
    competitionSourceDetails: params => apiRequest({ ...params, path: '/reports/competition-source-details', method: 'get' }),
    topPosters: params => apiRequest({ ...params, path: '/reports/top-posters', method: 'get' }),
    balanceIssues: params => apiRequest({ ...params, path: '/reports/balance-issues', method: 'get' }),
    accountsPayable: params => apiRequest({ ...params, path: '/reports/accounts-payable', method: 'get' }), 
    leadsAgents: params => apiRequest({ ...params, path: '/reports/leads-agents', method: 'get' }),
    leadsAgentsDetails: params => apiRequest({ ...params, path: '/reports/leads-agents-details', method: 'get' }),
    leadsSources: params => apiRequest({ ...params, path: '/reports/leads-sources', method: 'get' }),
    leadsSourcesDetails: params => apiRequest({ ...params, path: '/reports/leads-sources-details', method: 'get' }),
    leadsReferrals: params => apiRequest({ ...params, path: '/reports/leads-referrals', method: 'get' }),
    leadsReferralsDetails: params => apiRequest({ ...params, path: '/reports/leads-referrals-details', method: 'get' }),
    leadsReflows: params => apiRequest({ ...params, path: '/reports/leads-reflows', method: 'get' }),
    leadsReflowsDetails: params => apiRequest({ ...params, path: '/reports/leads-reflows-details', method: 'get' }),
    leadsExport: params => apiRequest({ ...params, path: '/reports/leads-export', method: 'get' }),
    leadsFollowups: params => apiRequest({ ...params, path: '/reports/leads-followups', method: 'get' }),
    cancellation: params => apiRequest({ ...params, path: '/reports/cancellation', method: 'get' }),
    cancellationDetails: params => apiRequest({ ...params, path: '/reports/cancellation-details', method: 'get' }),
  },
  settings: {
    get: ({ name = '', ...params }) => apiRequest({ ...params, path: `/settings/${name}`, method: 'get' }),
    put: ({ name, ...params }) => apiRequest({ ...params, path: `/settings/${name}`, method: 'put' }),
  },
  sources: {
    get: params => apiRequest({ ...params, path: '/sources', method: 'get' }),
    post: params => apiRequest({ ...params, path: '/sources', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/sources/${id}`, method: 'put' }),
  },
  permissions: {
    get: params => apiRequest({ ...params, path: '/permissions', method: 'get' }),
    post: params => apiRequest({ ...params, path: '/permissions', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/permissions/${id}`, method: 'put' }),
  },
  scoreboards: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/scoreboards/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/scoreboards', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/scoreboards/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/scoreboards/${id}`, method: 'delete' }),
  },
  states: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/states/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/states', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/states/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/states/${id}`, method: 'delete' }),
  },
  tasks: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/tasks/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/tasks', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/tasks/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/tasks/${id}`, method: 'delete' }),
    toggleDone: ({ id }) => apiRequest({ path: `/tasks/${id}/toggle-done`, method: 'post' }),
  },
  users: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/users/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/users', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/users/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/users/${id}`, method: 'delete' }),
    dropdown: params => apiRequest({ ...params, path: `/users/dropdown`, method: 'get' }),
    me: {
      get: params => apiRequest({ ...params, path: `/users/me`, method: 'get' }),
    },
    photo: {
      post: ({ id, ...params }) => apiRequest({ ...params, path: `/users/${id}/photo`, method: 'post' }),
      delete: ({ id, ...params }) => apiRequest({ ...params, path: `/users/${id}/photo`, method: 'delete' }),
    },
    restore: ({ id, ...params }) => apiRequest({ ...params, path: `/users/${id}/restore`, method: 'post' }),
    sources: {
      get: ({ userId, ...params }) => apiRequest({ ...params, path: `/users/${userId}/sources`, method: 'get' }),
      delete: ({ userId, id, ...params }) => apiRequest({ ...params, path: `/users/${userId}/sources/${id}`, method: 'delete' }),
      post: ({ userId, ...params }) => apiRequest({ ...params, path: `/users/${userId}/sources`, method: 'post' }),
    },
    permissions: {
      get: ({ userId, ...params }) => apiRequest({ ...params, path: `/users/${userId}/permissions`, method: 'get' }),
      delete: ({ userId, id, ...params }) => apiRequest({ ...params, path: `/users/${userId}/permissions/${id}`, method: 'delete' }),
      post: ({ userId, ...params }) => apiRequest({ ...params, path: `/users/${userId}/permissions`, method: 'post' }),
    },
    login: {
      post: params => apiRequest({ ...params, path: '/users/login', method: 'post' }),
    },
    logout: {
      post: params => apiRequest({ ...params, path: '/users/logout', method: 'post' }),
    },
    leadViews: {
      get: ({ userId, ...params }) => apiRequest({ ...params, path: `/users/${userId}/lead-views`, method: 'get' }),
    },
    authLogs: {
      get: ({ userId, ...params }) => apiRequest({ ...params, path: `/users/${userId}/auth-logs`, method: 'get' }),
    },
  },
  rolePermissions: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/role-permissions/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/role-permissions', method: 'post' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/role-permissions/${id}`, method: 'delete' }),
  },
  dashboardUsers: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/dashboard-users/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/dashboard-users', method: 'post' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/dashboard-users/${id}`, method: 'delete' }),
  },
  vehicles: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/vehicles/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/vehicles', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/vehicles/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/vehicles/${id}`, method: 'delete' }),
    body: {
      get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/vehicles/body/${id}`, method: 'get' }),
      post: params => apiRequest({ ...params, path: '/vehicles/body', method: 'post' }),
    },
    makes: {
      get: ({ ...params }) => apiRequest({ ...params, path: `/vehicles/makes`, method: 'get' }),
    },
    years: {
      get: ({ ...params }) => apiRequest({ ...params, path: `/vehicles/years`, method: 'get' }),
    },
    models: {
      get: ({ ...params }) => apiRequest({ ...params, path: `/vehicles/models`, method: 'get' }),
    },
  },
  cdVehicleTypes: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/cd-vehicle-types/${id}`, method: 'get' }),
  },
  vehicleTypes: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/vehicle-types/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/vehicle-types', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/vehicle-types/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/vehicle-types/${id}`, method: 'delete' }),
  },
  vehicleMakes: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/vehicle-makes/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/vehicle-makes', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/vehicle-makes/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/vehicle-makes/${id}`, method: 'delete' }),
  },
  zips: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/zips/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/zips', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/zips/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/zips/${id}`, method: 'delete' }),
  },
  roles: {
    get: ({ id = '', ...params }) => apiRequest({ ...params, path: `/roles/${id}`, method: 'get' }),
    post: params => apiRequest({ ...params, path: '/roles', method: 'post' }),
    put: ({ id, ...params }) => apiRequest({ ...params, path: `/roles/${id}`, method: 'put' }),
    delete: ({ id, ...params }) => apiRequest({ ...params, path: `/roles/${id}`, method: 'delete' }),
  },
};
