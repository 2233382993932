import { objectToCamelCase, objectToSnakeCase } from './caseHelpers';
import { handleApiError } from './handleApiError';
import axios from 'axios';

/**
 * A simple Axios wrapper that takes care of
 * 1. Converting params from camelCase to snake_case when making a request
 * 2. Converting response from snake_case attributes to camelCase
 * 3. Gracefully handling arrors and returning a `status` attributes so that
 *    errors can be handled in consisteny way.
 */
export async function apiRequest({ path, params = {}, body = {}, method = 'get', blobRespone = false }) {
  const pathNoSlash = path.endsWith('/') ? path.slice(0, -1) : path;
  if (!['get', 'post', 'put', 'delete'].includes(method)) {
    throw new Error(`Invalid HTTP verb "${method}" in "${path}" HTTP request`);
  }
  try {
    let config = {
      url: pathNoSlash,
      method: method,
      params: objectToSnakeCase(params)
    };
    if (blobRespone) config.responseType = 'blob';
    // If the body is a FormData, just attach it as is.
    if (body instanceof FormData) {
      config.data = body;
    } else {
      // Only set data if not a GET or DELETE, since they typically don't send a request body.
      // However, Axios won't send data for GET requests anyway, so it's safe to set it.
      config.data = objectToSnakeCase(body);
    }
    const apiResponse = await axios.request(config);
    if (blobRespone) return apiResponse;
    return objectToCamelCase(apiResponse);
  } catch (error) {
    return handleApiError(error);
  }
}
